<template>
    <div
        class="page address-list iphone-x-adaptation"
        :class="{ 'show-btn': pageType == 1 || pageType == 2 || pageType == 3 }"
    >
        <div class="add-box">
            <div class="btn-add">
                <div class="text" @click="addAddress">添加新地址</div>
            </div>
        </div>
        <div class="list">
            <div
                class="item"
                :class="{
                    select:
                        (pageType == 1 || pageType == 2 || pageType == 3) && selectIndex == index,
                }"
                v-for="(item, index) in addressList"
                :key="item.id"
                @click="clickItem(item, index)"
            >
                <div class="edit" @click.stop="editItem(item)"></div>
                <div class="main-address">{{ item.mainAddress }}</div>
                <div class="detail-address">{{ item.detailAddress }}</div>
                <div class="name">{{ item.name }} {{ item.mobile }}</div>
                <div
                    class="lastname"
                    :class="[{ man: item.gender == 0 }, { woman: item.gender == 1 }]"
                >
                    {{ item.name[0] }}
                </div>
            </div>
        </div>
        <div class="btn iphone-x-adaptation" :class="{ select: selectIndex > -1 }" @click="submit">
            确定
        </div>
    </div>
</template>
<script>
import { GET_ADDRESS_LIST } from 'api';
import { PAGE_ADDRESS_DETAIL, PAGE_STOP_MENU, PAGE_RESTAURANT_SELECT } from 'page';
import { QZ_ADDRESS_SAVE_ID, QZ_KFC_CART_NAME } from 'constant';
export default {
    data() {
        return {
            addressList: [],
            selectIndex: -1,
            pageType: 0,
        };
    },
    created() {
        if (this.$store.getters.getExposeMethod.expandName == 'QiShiKa') {
            document.title = '肯德基外卖';
        }
        this.pageType = this.$route.query.type;
        this.getData();
    },
    methods: {
        getData() {
            this.$util
                .post(GET_ADDRESS_LIST, {
                    pageIndex: 1,
                    pageSize: 500,
                })
                .then(res => {
                    if (res.success) {
                        this.addressList = res.data;
                    }
                });
        },
        addAddress() {
            this.$util.pageJump(PAGE_ADDRESS_DETAIL, {
                type: 0,
            });
        },
        clickItem(item, index) {
            if (this.selectIndex == index) {
                this.selectIndex = -1;
            } else {
                this.selectIndex = index;
            }
        },
        editItem(item) {
            this.$util.pageJump(PAGE_ADDRESS_DETAIL, {
                type: 1,
                id: item.id,
            });
        },
        submit() {
            if (this.selectIndex == -1) {
                return;
            }
            if (this.pageType == 1) {
                let msg = `肯德基点餐为第三方供应商提供的代下单服务。如遇官方给您电话，告知地址无法配送，请及时联系点餐首页在线客服，申请退款。`;
                this.$confirm.open({
                    title: '温馨提示',
                    message: this.$store.getters.getExposeMethod.takeoutTip || msg,
                    hideCancelBtn: true,
                    isShieldClose: false,
                    confirmText: '知道了',
                    success: res => {
                        if (res.success) {
                            window.localStorage.setItem(QZ_KFC_CART_NAME, '[]');
                            this.submitNext();
                        }
                    },
                });
            } else if (this.pageType == 3) {
                this.submitNext();
            } else {
                this.submitNext();
            }
        },
        submitNext() {
            if (this.pageType == 1) {
                this.$util.pageJump(PAGE_STOP_MENU, {
                    type: 1,
                    addressId: this.addressList[this.selectIndex].id,
                });
            } else if (this.pageType == 2) {
                window.localStorage.setItem(
                    QZ_ADDRESS_SAVE_ID,
                    this.addressList[this.selectIndex].id
                );
                this.$util.pageBack();
            } else if (this.pageType == 3) {
                this.$util.pageJump(PAGE_RESTAURANT_SELECT, {
                    type: 1,
                    addressId: this.addressList[this.selectIndex].id,
                    lat: this.addressList[this.selectIndex].lat,
                    lon: this.addressList[this.selectIndex].lon,
                });
            }
        },
    },
};
</script>
<style lang="less" scoped>
@import url('~@/lib/base.less');
@addHeight: .pxToRem(155) [];
@btnHeight: .pxToRem(100) [];

.add-box {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: @addHeight;
    line-height: @addHeight;
    background: white;
    z-index: 10;
    border-radius: 0 0 .pxToRem(40) [] .pxToRem(40) [];
    .btn-add {
        position: absolute;
        left: .pxToRem(50) [];
        right: .pxToRem(50) [];
        top: .pxToRem(30) [];
        height: .pxToRem(88) [];
        line-height: .pxToRem(88) [];
        text-align: center;
        box-sizing: border-box;
        border: .pxToRem(1) [] solid #d9d9d9;
        border-radius: .pxToRem(44) [];
        .text {
            display: inline-block;
            text-indent: .pxToRem(74) [];
            font-size: .pxToRem(30) [];
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
            &::after {
                content: ' ';
                .base-center-y;
                left: 0;
                width: .pxToRem(44) [];
                height: .pxToRem(44) [];
                background: url('~@/assets/icons/icon-circular-plus.png');
                background-size: cover;
            }
        }
    }
}
.list {
    margin-top: calc(@addHeight + .pxToRem(10) []);
    padding: 0 .pxToRem(20) [];
    padding-bottom: .pxToRem(10) [];
    .item {
        width: 100%;
        background: white;
        padding-right: .pxToRem(30) [];
        border-radius: .pxToRem(30) [];
        margin-top: .pxToRem(10) [];
        padding-left: .pxToRem(108) [];
        padding-right: .pxToRem(108) [];
        border: .pxToRem(1) [] solid white;
        flex-direction: column;
        justify-content: flex-start;
        box-sizing: border-box;

        .main-address {
            padding-top: .pxToRem(33) [];
            line-height: .pxToRem(40) [];
            font-size: .pxToRem(26) [];
            color: rgba(0, 0, 0, 0.9);
        }
        .detail-address {
            padding-top: .pxToRem(10) [];
            line-height: .pxToRem(40) [];
            font-size: .pxToRem(26) [];
            color: rgba(0, 0, 0, 0.9);
        }
        .name {
            padding-top: .pxToRem(10) [];
            height: .pxToRem(40) [];
            font-size: .pxToRem(24) [];
            color: rgba(0, 0, 0, 0.5);
            line-height: .pxToRem(36) [];
            padding-bottom: .pxToRem(27) [];
        }
        .lastname {
            position: absolute;
            top: .pxToRem(30) [];
            left: .pxToRem(30) [];
            .sq(48);
            box-sizing: border-box;
            text-align: center;
            line-height: .pxToRem(48) [];
            border-radius: .pxToRem(24) [];
            font-size: .pxToRem(24) [];
            &.man {
                color: #2866de;
                border: .pxToRem(1) [] solid #b7d0fe;
                background: #eef4ff;
            }
            &.woman {
                color: @theme_color_first;
                border: .pxToRem(1) [] solid @theme_color_first;
                background: #fdf0f0;
            }
        }
        .edit {
            position: absolute;
            top: 0;
            right: 0;
            width: .pxToRem(92) [];
            height: .pxToRem(106) [];
            &:after {
                content: ' ';
                .base-center;
                .sq(32);
                background: url('~@/assets/icons/icon-address-edit.png');
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
        &.select {
            border: .pxToRem(1) [] solid @theme_color_first;
        }
    }
}
.btn {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: @btnHeight;
    line-height: @btnHeight;
    text-align: center;
    background: #edc5c5;
    color: white;
    font-size: .pxToRem(32) [];
    font-weight: 500;
    border-radius: .pxToRem(30) [] .pxToRem(30) [] 0 0;
    &.select {
        background: rgba(196, 62, 60, 1);
    }
}
.show-btn {
    .list {
        padding-bottom: calc(@btnHeight + .pxToRem(10) []);
    }
    .btn {
        display: block;
    }
}
</style>
